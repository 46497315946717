/**
 * This file controls which trackers will be included in the build.
 * Trackers are provided as an env variables
 * include/exlude trackers based on those env variables.
 * Hydra is alwayas included, regardless of provided variable
 */
import { Trackers } from '../const';
import * as braze from '../trackers/braze';
import * as clevertap from '../trackers/clevertap';
import * as facebook from '../trackers/facebook';
import * as ga from '../trackers/ga';
import * as gtag from '../trackers/gtag';
import * as gtm from '../trackers/gtm';
import * as hydra from '../trackers/hydra';
import * as laquesis from '../trackers/laquesis';
import * as mixpanel from '../trackers/mixpanel';

/**
 * @type {Record<keyof typeof Trackers, Tracker>}
 */
const trackers = {
  [Trackers.Hydra]: hydra,
};

if (process.env.__LAQUESIS__) {
  trackers[Trackers.Laquesis] = laquesis;
}

if (process.env.__GA__) {
  trackers[Trackers.GA] = ga;
}

if (process.env.__MIXPANEL__) {
  trackers[Trackers.Mixpanel] = mixpanel;
}

if (process.env.__CLEVERTAP__) {
  trackers[Trackers.Clevertap] = clevertap;
}

if (process.env.__GTM__) {
  trackers[Trackers.GTM] = gtm;
}

if (process.env.__GTAG__) {
  trackers[Trackers.GTAG] = gtag;
}

if (process.env.__FACEBOOK__) {
  trackers[Trackers.Facebook] = facebook;
}

if (process.env.__BRAZE__) {
  trackers[Trackers.Braze] = braze;
}

export default trackers;
