import { convertToLatin, deucex } from '../../utils';

// Internal function - Collect the invite param from the URL
export function getInvite() {
  let finalInvite;
  let tmpInvite = [];
  const queries = window.location.search.replace(/^\?/, '').split('&');
  const searchObject = queries.reduce((acc, q) => {
    const split = q.split('=');
    acc[split[0]] = split[1];
    return acc;
  }, {});

  // Try with invite
  const invite = searchObject.invite || null;
  if (invite !== null) {
    tmpInvite = parseInvite(invite);
  } else {
    // Try with utm
    if (searchObject.utm_source) {
      tmpInvite = parseUtm(searchObject);
    }
  }

  if (tmpInvite[0]) {
    const source = tmpInvite[0];
    tmpInvite.shift();

    finalInvite =
      convertToLatin(deucex(source))
        .replace(/\s/g, '')
        .replace(/NINJA_SEPARATOR/g, '-') +
      '_' +
      convertToLatin(deucex(tmpInvite.join('--')))
        .replace(/\s/g, '')
        .replace(/NINJA_SEPARATOR/g, '-');

    return finalInvite;
  } else {
    return null;
  }
}

/**
 * ITM is different from UTM. It needs to be separated and is tracked with different property
 */
export function getItmInvite() {
  var queries = window.location.search.replace(/^\?/, '').split('&');
  var searchObject = {};
  var tmpInvite = [];

  for (let i = 0; i < queries.length; i = i + 1) {
    const split = queries[i].split('=');
    searchObject[split[0]] = split[1];
  }

  if (searchObject.itm_source) {
    tmpInvite = parseItm(searchObject);
  }

  if (tmpInvite.length > 0) {
    const source = tmpInvite[0];
    tmpInvite.shift();

    const finalInvite =
      convertToLatin(deucex(source))
        .replace(/\s/g, '')
        .replace(/NINJA_SEPARATOR/g, '-') +
      '_' +
      convertToLatin(deucex(tmpInvite.join('--')))
        .replace(/\s/g, '')
        .replace(/NINJA_SEPARATOR/g, '-');

    return finalInvite;
  } else {
    return null;
  }
}

/**
 * Internal function - Get the invite throw invite
 * @param {string} invite
 * @returns Array
 */
export function parseInvite(invite) {
  const inviteParams = invite.match(/^\s*([a-zA-Z0-9\-]{1,64})(?:_([a-zA-Z0-9%\-]{0,128})).*/);

  if (inviteParams !== null && inviteParams.length > 0) {
    return [inviteParams[1], inviteParams[2]];
  } else {
    return [];
  }
}

/**
 * Internal function - Get the invite throw utm
 * @param {Record<string, any>} searchObject
 * @returns string[]
 */
export function parseUtm(searchObject = {}) {
  const params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_id'];

  return params.reduce((acc, p) => {
    if (p in searchObject) {
      acc.push(searchObject[p].replace(/[\-_\.]/g, 'NINJA_SEPARATOR'));
    }
    return acc;
  }, []);
}

/**
 * Internal function - Parse internal campaign params
 * @param {Record<string, any>} searchObject
 * @returns string[]
 */
export function parseItm(searchObject) {
  const params = ['itm_source', 'itm_medium', 'itm_campaign', 'itm_term', 'itm_content', 'itm_id'];

  return params.reduce((acc, param) => {
    if (param in searchObject) {
      acc.push(searchObject[param].replace(/[\-_\.]/g, 'NINJA_SEPARATOR'));
    }
    return acc;
  }, []);
}
