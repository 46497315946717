import { CategoryCodes, isConsentGiven, subscribeToConsentChange, waitForUserDecision } from '@olxeu-eprivacy-storage/js';
import { configTracking } from './config/configTracking';
import ninjaConfig from './config/ninja';
import regionConfig from './config/region';
import { Trackers } from './const';
import { cleanCookie, initCookieStorage, isEprivacyStorageRequired } from './cookies';
import { checkParam, currentTracker, getPluginList, getSessionParams, getTrackerList } from './core';
import { flushDelayed, trackEventDelayed, trackPageDelayed } from './core/delayedTracking';
import trackers from './core/trackers';
import { trackError } from './core/utils';
import { init as initLaquesis } from './trackers/laquesis';
import { eucex, getCurrentPath } from './utils';

// TODO: Find a better way to get this info from the SDK
const REQUIRED_CATEGORIES = [CategoryCodes.C0002, CategoryCodes.C0003];
let originalDataLayerPush;

function initNinja() {
  try {
    let foundTrackPage;

    if (regionConfig.custom[ninjaConfig.siteUrl]) {
      // Setup dataLayer
      ninjaConfig.dataLayer = ninjaConfig.dataLayer || [];
      ninjaConfig.dataLayerDelayed = ninjaConfig.dataLayerDelayed || [];

      // Overwrite function push
      if (!originalDataLayerPush) {
        originalDataLayerPush = ninjaConfig.dataLayer.push;
      }
      ninjaConfig.dataLayer.push = function (params) {
        let result = originalDataLayerPush.apply(ninjaConfig.dataLayer, [params]);

        checkParam();

        return result;
      };

      // Set internal queue
      if (!window.trackingQueue) {
        window.trackingQueue = [];
      }
      window.trackingQueue.push = function (func) {
        if (typeof func === 'function') {
          func();
          return 0;
        }
        return -1;
      };

      while (window.trackingQueue.length > 0) {
        window.trackingQueue.push(window.trackingQueue.shift());
      }

      // Cookies are read only on track action. Laquesis needs sessionLong to init
      // Force cookie reading if no default event or no existing dataLayer events have been processed
      if (!ninjaConfig.currentSession) {
        getSessionParams(true);
      }
      // Init laquesis

      if (getPluginList().includes(Trackers.Laquesis)) {
        setTimeout(initLaquesis, 0);
      }

      // Call async check
      for (const evt of ninjaConfig.dataLayer) {
        if (evt.trackPage) {
          foundTrackPage = true;
        }
      }
      if (!foundTrackPage && !ninjaConfig.disableDefaultTrackPage) {
        if (!configTracking.unitTest) {
          if (window.location.pathname) {
            // Track the current path name if there is no trackPage when the library is called
            ninjaConfig.dataLayer.push({ trackPage: eucex(getCurrentPath()) });
          } else {
            ninjaConfig.dataLayer.push({ trackPage: 'home' });
          }
        }
      } else {
        checkParam();
      }
    }
  } catch (error) {
    if (typeof trackError === 'function') {
      trackError('JAVASCRIPT_ERROR', currentTracker, '', error);
    }
  }
}

function cleanupNinjaCookies() {
  let usedCookies = ['onap', 'lqonap', 'laquesis', 'laquesisff', 'laquesissu', 'lqstatus', 'laquesisqa', 'invite', 'ldTd'];

  for (let i = 0; i < usedCookies.length; i++) {
    cleanCookie(usedCookies[i]);
  }
}

function cleanupNinja() {
  cleanupNinjaCookies();

  if (ninjaConfig.dataLayer && originalDataLayerPush) {
    ninjaConfig.dataLayer.push = originalDataLayerPush; //removes Ninja logic from dataLayer push method - no tracking will be sent
  }

  // disable each tracker if cleanup logic exists
  for (const tracker of getTrackerList()) {
    if (typeof trackers[tracker].cleanup === 'function') {
      trackers[tracker].cleanup();
    }
  }
}

/** Check for consent for all required categories */
function hasRequiredConsent() {
  let ret = true;

  for (let i = 0; i < REQUIRED_CATEGORIES.length; i++) {
    ret = ret && isConsentGiven(REQUIRED_CATEGORIES[i]);

    if (!ret) {
      break;
    }
  }

  return ret;
}

/**
 * Decide what to do when user has given consent and cookie storage is ready
 */
function initCallback() {
  if (hasRequiredConsent()) {
    initNinja();
  } else {
    cleanupNinja(); // clean all cookies if consent is not given and disable trackers
  }
}

/** Start ninja load */

// Check if current region requires the ePrivacy SDK
if (isEprivacyStorageRequired()) {
  // Wait for user to click the cookie banner
  waitForUserDecision().then(() => {
    initCookieStorage(() => {
      setTimeout(() => {
        initCallback();

        // listen for consent change - if given -> init ninja, if revoked -> cleanup
        subscribeToConsentChange(() => {
          initCallback();

          // listen for consent change - if given -> init ninja, if revoked -> cleanup
          subscribeToConsentChange(() => initCallback());
        });
      }, 100);
    });
  });
} else {
  // current behaviour - init everything and use cookieStorage with no restrictions
  initCookieStorage(() => {
    setTimeout(() => initNinja(), 100);
  });
}

/**
 * Track ninja event
 * @param {String | String[]} eventName Event to track
 * @param {Record<string, any>} params
 */
function trackEvent(eventName, params) {
  if (eventName && ninjaConfig.dataLayer) {
    const trackParams = Object.assign({}, params || {}, {
      trackEvent: Array.isArray(eventName) ? eventName : [eventName],
    });
    ninjaConfig.dataLayer.push(trackParams);
  }
}

/**
 * Track page
 * @param {String} name page name
 * @param {Record<string, any>} params
 */
function trackPage(name, params) {
  const trackParams = Object.assign({}, params || {}, { trackPage: name });

  ninjaConfig.dataLayer.push(trackParams);
}

/**
 * Track page
 * @param {String} name page name
 * @param {Record<string, any>} params
 */
function trackPerformanceEvent(name, params) {
  var trackParams = Object.assign({}, params || {}, { trackPerformanceEvent: name });

  ninjaConfig.dataLayer.push(trackParams);
}

export default {
  trackEvent,
  trackPage,
  trackPerformanceEvent,

  trackEventDelayed,
  trackPageDelayed,
  flushDelayed,
};
