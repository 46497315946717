import hydraConfig from '../../../config/hydra';
import ninjaConfig from '../../../config/ninja';
import regionConfig from '../../../config/region';
import { Channel, ChannelName, HYDRA_HOST } from '../../../const';
import { trackWithBeacon } from '../../../core/utils';
import { getProtocol } from '../../../utils';
import { currentPlatform } from '../../hydra';

export const SURVEY_TRIGGER_MATCH = 'survey_trigger_match';
export const SURVEY_LOAD = 'survey_load';
export const SURVEY_ALREADY_ACTIVE = 'survey_already_active';
export const SURVEY_LOAD_ERROR = 'survey_load_error';
export const SURVEY_RENDER_START = 'survey_render_start';
export const SURVEY_RENDER = 'survey_render';
export const SURVEY_MARK_SHOWN = 'survey_mark_shown';

/**
 * Logs survey activity (non-related to main survey tracking) - trigger, fetch, open, etc.
 * Not checked by Grumpy ninja - everything is tracked "as-is"
 * @param {string} eventName
 * @param {number} surveyId
 * @param {Record<string, unknown>} debugProps
 */
export function debugSurvey(eventName, surveyId, debugProps) {
  const url = getProtocol() + HYDRA_HOST + hydraConfig.survey_path;
  let params = {
    eN: eventName,
    survey_id: surveyId,
    debug_log: JSON.stringify(debugProps),
    sl: ninjaConfig.currentSessionLong,
    s: ninjaConfig.currentSession,
    cC: hydraConfig.params.cC,
    bR: hydraConfig.params.bR,
  };

  // Common Properties

  if (ninjaConfig.platform === Channel.Mobile) {
    params.cH = ChannelName.Mobile;
  } else if (ninjaConfig.platform === Channel.Desktop) {
    params.cH = ChannelName.Desktop;
  } else if (currentPlatform) {
    params.cH = currentPlatform;
  } else {
    params.cH = Channel.DesktopMobile;
  }

  // Matrix Version
  if (regionConfig.version) {
    params.mv = regionConfig.version;
  }

  // Environment
  if (ninjaConfig.environment !== 'production') {
    params.env = 'dev';
  }

  // Timestamp
  params.t = new Date().getTime();

  // console.log('Survey debug:', params);
  trackWithBeacon(url, params);
}
