import ninjaConfig from '@ninja/ninja/config/ninja';
import { LAQUESIS_SURVEY_CSS_FILENAME, NINJA_CDN } from '@ninja/ninja/const';
import { loadCSS } from '@ninja/ninja/utils';

export let isCSSLoaded = false;

/**
 * Loads styles for surveys
 * @returns {Promise<void>}
 */
export function loadSurveyStyles() {
  return new window.Promise(resolve => {
    if (ninjaConfig.asyncSurveyStyles && !isCSSLoaded) {
      const theme = ninjaConfig.surveyTheme || 'default';
      const filename = `${LAQUESIS_SURVEY_CSS_FILENAME}-${theme}.css`;
      loadCSS(`${NINJA_CDN}/${filename}`, 'laquesisSurveyStyles', () => {
        isCSSLoaded = true;
        resolve();
      });
    } else {
      isCSSLoaded = true;
      resolve();
    }
  });
}
